window.$ = window.jQuery = require('jquery');

$(document).ready(function () {

    // hamburger menu
    (function () {
        $('.hamburger-menu, .mobile-nav a').on('click', function () {
            $('.bar').toggleClass('animate');
            var mobileNav = $('.mobile-nav');
            mobileNav.toggleClass('hide show');
            $('.wrapper').toggleClass('active');
        })
    })();

    (function highlightNav() {
        var prev; //keep track of previous selected link
        var isVisible = function (el) {
            el = $(el);

            if (!el || el.length === 0) {
                return false
            }

            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = el.offset().top;
            var elemBottom = elemTop + el.height();
            return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom));
        };

        $(window).scroll(function () {
            $('.mobile-nav a').each(function (index, el) {
                el = $(el);
                if (isVisible(el.attr('href'))) {
                    if (prev) {
                        prev.removeClass('active');
                    }
                    el.addClass('active');
                    prev = el;

                    //break early to keep highlight on the first/highest visible element
                    //remove this you want the link for the lowest/last visible element to be set instead
                    return false;
                } else {
                    el.removeClass('active');
                }
            });
        });

        //trigger the scroll handler to highlight on page load
        $(window).scroll();

        $('a.smoth').click(function () {
            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top + 50
            }, 500);
            return false;
        });
        $('a.home').click(function () {
            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top
            }, 500);
            return false;
        });
    })();
});
